import styled from 'styled-components'

import _Button from '~/components/button'
import Icon from '~/components/icon'
import { color, font, device } from '~/styles/utils'

import type { FixedGlyphIcon } from '~/components/icon'

const bgColorMapper = {
  alert: color('ikonGold'),
  message: color('blueCreek'),
  info: color('blueCreek'),
}

export const colorMapper = {
  alert: color('ikonBlue'),
  message: color('white'),
  info: color('white'),
}

export const Banner = styled.div<{
  $msgtype: string
}>`
  background-color: ${({ $msgtype, theme }) =>
    bgColorMapper[$msgtype] || theme.color.background.accent};
  @media ${device('notMobile')} {
    padding: 5px 0;
  }
`

export const BannerContent = styled.div`
  max-width: 1220px;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 auto;
  @media ${device('notMobile')} {
    height: 26px;
    width: 95%;
  }
`

export const PromoIcon: FixedGlyphIcon = styled(Icon).attrs(() => ({
  glyph: 'tag',
}))`
  margin-right: 0;
  padding-top: 17px;
  color: ${({ theme }) => theme.color.text.default};
  @media ${device('notMobile')} {
    margin: 5px;
    padding: 0;
  }
`

export const Left = styled.div`
  margin: 0;
  display: flex;
  padding-left: 15px;

  @media ${device('notMobile')} {
    padding: 0;
    align-items: center;
  }
`

export const TextContent = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 15px;
  && > * {
    margin: 0 10px 0 0;
  }
  @media ${device('notMobile')} {
    padding: 0;
    flex-direction: row;
    align-items: center;
  }
`

export const Right = styled.div`
  margin-left: auto;
  @media ${device('notMobile')} {
    margin-right: 0;
  }
`

export const Title = styled.h3`
  ${font('demi')};
  font-size: 14px;
  color: ${({ theme }) => theme.color.text.default};
  display: inline-block;
  text-transform: uppercase;
`

export const Text = styled.p<{
  msgtype?: string
}>`
  margin: 0;
  ${font('base')};
  font-size: 14px;
  color: ${({ msgtype, theme }) =>
    msgtype ? colorMapper[msgtype] : theme.color.text.default};
  display: inline-block;
`

export const Button = styled(_Button).attrs(() => ({
  type: 'plain',
}))`
  color: ${color('blueCreek')};
  ${font('demi')};
  font-size: 14px;
  text-transform: uppercase;
`
Button.displayName = 'ModalButton'

export const CloseButton: FixedGlyphIcon<{
  msgtype?: string
}> = styled(_Button).attrs(() => ({
  glyph: 'xmark',
  type: 'unstyled',
}))<{
  msgtype?: string
}>`
  &&& {
    color: ${({ msgtype, theme }) =>
      msgtype ? colorMapper[msgtype] : theme.color.text.default};
    width: 35px;
    text-align: center;
    font-size: 14px;
  }
`
