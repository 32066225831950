import classNames from 'classnames'
import React, { Component } from 'react'

import { ACTION_KEYCODES } from '../../config'
import Icon from '../icon'

import type { ReactNode } from 'react'

import './content-toggle-v2.scss'

type Props = {
  className?: string
  openLabel?: ReactNode
  closeLabel?: ReactNode
  defaultOpen?: boolean
  children?: ReactNode
}

type State = {
  open: boolean
}

export default class ContentToggleV2 extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { defaultOpen } = props

    if (typeof defaultOpen === 'boolean') {
      this.state = {
        open: defaultOpen,
      }
    } else {
      this.state = {
        open: true,
      }
    }
  }

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  handleKeyDown = (e: { keyCode: number; preventDefault: () => void }) => {
    if (ACTION_KEYCODES.includes(e.keyCode)) {
      e.preventDefault()
      this.handleToggle()
    }
  }

  render() {
    const closeLabel = this.props.closeLabel || <Icon glyph="minus" />
    const openLabel = this.props.openLabel || <Icon glyph="plus" />
    const toggleClass = classNames('toggle', {
      'plus-minus': !this.props.closeLabel && !this.props.openLabel,
    })

    const className = classNames('amp-content-toggle-v2', this.props.className)
    return (
      <div className={className}>
        <div className="content">
          {this.state.open ? this.props.children : null}
        </div>
        <div
          className={toggleClass}
          tabIndex={0}
          onClick={this.handleToggle}
          onKeyDown={this.handleKeyDown}
          role="button"
          data-testid={this.state.open ? 'collapse-button' : 'expand-button'}
        >
          {this.state.open ? closeLabel : openLabel}
        </div>
      </div>
    )
  }
}
